import React from 'react';
import InputModal from '../../widgets/inputModal';
import {Alert, Button} from 'reactstrap';

const ResetPasswordForm = (props) =>{
  const {handleSubmit, handleInvalid, setRef, handleChange, password} = props
  const {loading, registerSuccess, registrationError, errorMessage, successMessage} = props
  
  return(
    <>
      <h1>Reestablecer contraseña</h1>
      <form 
        className="needs-validation"
        ref={setRef}
        onSubmit={handleSubmit} 
        onInvalid={handleInvalid}
      >
        <div className="Register__body">
          
          {
            registerSuccess && 
            <Alert color="success">
              {successMessage}
            </Alert>
          }
          {
            registrationError && 
            <Alert color="danger">
              {errorMessage}
            </Alert>
          }
          <InputModal
            id="new"
            type="password"
            title="Nueva Contraseña"
            className="form-control"
            placeholder="Ingresa la nueva contraseña"
            handleChange={handleChange}
            defaultValue={password.new}
          />
          <InputModal
            id="again"
            type="password"
            title="Confirmar Contraseña"
            className="form-control"
            placeholder="Vuelve a ingresar la contraseña"
            handleChange={handleChange}
            defaultValue={password.again}
          />
          <div className="submit-btn-area">
            <Button disabled={loading} color="primary" className="btn btn-primary" type="submit">
              {loading ? 'Loading ...' : 'Enviar'} <i className="ti-arrow-right"></i>
            </Button>
          </div>
        </div>
      </form>
    </>
  )
}
export default ResetPasswordForm;