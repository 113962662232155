import { isUndefined } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { workshopCreation } from '../../api/apiURLs';
import GuestItem from './guestItem';
import NoGuests from './Widgets/noGuests';

const WorkshopGuests = ({ workshop, guests, workshopCreatorID, is_admin, users, total_admins }) => {
  const creatorBase = { attributes: {} };
  let creator = { ...creatorBase };

  console.log(guests)

  if (is_admin) {

    //Se busca al creador de la sesión en total_users
    creator = users.find(user => {
      return workshopCreatorID === user.id
    })

    if (!creator) {
      //Se busca al creador de la sesión en total_admins
      creator = total_admins.find(user => {
        return workshopCreatorID === user.id
      })
    }

    if (isUndefined(creator)) {
      creator = { ...creatorBase }
      console.log(`ID de usuario eliminado ${workshopCreatorID}`)
    }
  }

  const { name, is_admin: creator_is_admin } = creator.attributes

  return (
    <ul className="workshopItem__guests">
      {
        is_admin &&
        //Nombre del creador del taller y el codigo de acceso del mismo
        <li className="bg-info mb-2 p-3">
          {'Creador del taller: '}
          {
            !isUndefined(creator.id) ?
              <span className="font-weight-bold">
                {name} {creator_is_admin ? '(Admin)' : ''}
              </span>
              :
              <span className="font-weight-bold">
                Usuario Eliminado!!!
              </span>
          }
        </li>
      }
      {
        //Si hay invitados entonces se despliega la lista de cada uno de ellos
        guests.length ?
          guests.map((guest, index) => (
            <GuestItem
              key={index} guest={guest} workshop={workshop}
            />
          ))
          :
          <NoGuests />
      }
    </ul>
  )
}

const mapStateToProps = (state) => {
  const { Login: { user: { attributes: { is_admin }}}} = state;
  const { Login: { total_users: { rows: users }}} = state;
  const { Login: { total_admins }} = state;
  
  return {
    is_admin,
    users,
    total_admins
  }
}

export default connect(mapStateToProps)(WorkshopGuests);