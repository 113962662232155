import React from 'react';
import Button from './button';
import CustomModal from './customModal';
import InputModal from './inputModal';

const FinishModal = (props) =>{
  const {handleConfirm, btnColor, message, loading = false} = props;
  const {children, userEmail, handleChange} = props;
  return(
    <CustomModal className="center" type="small" zIndex={9999}>
      <form onSubmit={handleConfirm} className="confirmModal">
        
        <div className="confirmModal__description">
          <div className="pb-4">
            {children}
          </div>
          <p>{message}</p>
        </div>
        
        <InputModal 
          id="userEmail"
          type="email" 
          className="form-control"
          defaultValue={userEmail}
          handleChange={handleChange} 
          placeholder="Ingrese su correo para confirmar"
        />
        
        <div className={`buttons-container`}>
          <Button
            type="submit"
            //Si no hay nada en el input, el boton se desabilita
            //Si "loading = true" el boton se desabilita
            enabled={!userEmail.length || loading} 
            color={`${btnColor} font-weight-bold`}
          >
            {loading? 'Cargando...' : 'Aceptar'}
          </Button>
        </div>
        
      </form>
    </CustomModal>
  )
}
export default FinishModal;
