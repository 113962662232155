//Actions Types
import { CHECK_LOGIN, SET_USER_SUCCESSFUL, SET_LOADING, SET_PLANS_AT_STORE } from './actionTypes';
import { SET_TOTAL_USERS_AT_STORE, SET_ADMINS_AT_STORE } from './actionTypes';
import { SET_TOTAL_GUEST_AT_STORE, API_FAILED, UPDATE_LOGGED_USER } from './actionTypes';
import { NOTIFY_LOGIN_SUCCESSFUL } from './actionTypes';
import { SET_GUESTS_AT_STORE, KNOWN_TYPE_OF_ERROR, SET_USER_IN_LOCAL_STORAGE, LOGOUT } from './actionTypes';

//getDashboardData Functions
import { getGuest, getPlans, filterAndGetUsers, getAllWorkshopsGuests, getUserPlan, getAdmins, getWorkshops } from '../../../helpers/getDashboardData';
import { getUserWorkshops, getTotalGuest } from '../../../helpers/getDashboardData';

// Workshops actions
import { formatUserData, getUserData } from '../../../helpers/getUserData';
import { set_WorkshopGuests_AtStore, setWorkshopsAtStore } from '../../workshops/actions';
import { findAndReplace, sortByEarliestDate } from '../../../helpers/utils';

export const checkLogin = () => {
  return {
    type: CHECK_LOGIN
  }
}

export const notifyLogin = () => {
  return {
    type: NOTIFY_LOGIN_SUCCESSFUL,
  }
}

export const setUserSuccessful = (user) => {
  return {
    type: SET_USER_SUCCESSFUL,
    payload: user
  }
}

export const updateLoggedUser = (user) => {
  return {
    type: UPDATE_LOGGED_USER,
    payload: user
  }
}

export const logout = () => {
  return {
    type: LOGOUT,
  }
}

export const apiError = (error) => {
  return {
    type: API_FAILED,
    payload: error
  }
}

export const ErrorType = (messageError) => {
  //debugger
  return {
    type: KNOWN_TYPE_OF_ERROR,
    payload: messageError
  }
}

//It sets a loading state in the app, so the user shouldn't do any other request or action
export const setLoading = loading => {
  return {
    type: SET_LOADING,
    payload: loading
  }
}

//Sets of main data at Store
export const setPlansAtStore = plans => {
  return {
    type: SET_PLANS_AT_STORE,
    payload: plans
  }
}

export const setTotalGuestsAtStore = guests => {
  return {
    type: SET_TOTAL_GUEST_AT_STORE,
    payload: guests
  }
}

export const setTotalUsersAtStore = users => {
  return {
    type: SET_TOTAL_USERS_AT_STORE,
    payload: users
  }
}

export const setAdminsAtStore = users => {
  return {
    type: SET_ADMINS_AT_STORE,
    payload: users
  }
}

export const setGuestsAtStore = guests => {
  return {
    type: SET_GUESTS_AT_STORE,
    payload: guests
  }
}

export const setUserInLocalStorage = (userData) => {
  return {
    type: SET_USER_IN_LOCAL_STORAGE,
    payload: userData
  }
}

//Main action when there is a login
export const getUser = (email, password, history) => {
  return async dispatch => {
    const commonData = async (user, userData) => {

      const plans = await getPlans()
      dispatch(setPlansAtStore(plans))

      const admins = await getAdmins()
      dispatch(setAdminsAtStore(admins))

      //Setea todos los usuarios no admins
      const users = await filterAndGetUsers()
      dispatch(setTotalUsersAtStore(users))

      dispatch(updateGuests(user))

      //Se settea el usuario
      dispatch(setUserSuccessful(userData))
    }

    try {
      //Se obtiene y se setea la info del usuario en el local storage
      const userData = await getUserData(email, password)

      const user = await formatUserData(userData)
      dispatch(notifyLogin())

      //Si es admin lo redirecciona al dashboard, sino a la agenda
      if (user.is_admin) {

        const workshops = await getWorkshops()
        dispatch(setWorkshopsAtStore(workshops))

        const guests = await getTotalGuest()
        dispatch(setTotalGuestsAtStore(guests))

        await commonData(user, userData)
        history.push('/dashboard')

      } else if (user.is_active) {

        //Si el due_date ha expirado se le notificará cada vez que quiera entrar en la Agenda

        //Se obtiene el nombre del plan del usuario

        //Se obtiene el plan del usuario y se añade a .attributes
        userData.attributes.plan = await getUserPlan(userData)

        await commonData(user, userData)
        history.push('/agenda')

      } else {
        dispatch(apiError())
        dispatch(ErrorType('Su usuario no está activado'))
      }

    } catch (error) {
      console.log(error)
      // if(error.response.data.errors[0].detail.includes('bad password')){
      //   dispatch(ErrorType('La contraseña es invalida'))
      // }
      dispatch(apiError(error))
    }
  }
}

//updates sending request to the API
export const updateUsers = () => {
  return async dispatch => {
    const users = await filterAndGetUsers()
    dispatch(setTotalUsersAtStore(users))
  }
}

export const updateGuests = user => {
  return async dispatch => {

    //Se obtienen todos los invitados
    const guests = await getTotalGuest()
    dispatch(setTotalGuestsAtStore(guests))

    //Se obtienen los invitados del usuario
    const userGuests = await getGuest(user)
    dispatch(setGuestsAtStore(userGuests))

    //Se obtienen los talleres del usuario
    let userWorkshops = await getUserWorkshops(user);
    userWorkshops = sortByEarliestDate(userWorkshops)
    dispatch(setWorkshopsAtStore(userWorkshops));

    //Se obtienen los invitados de todos los talleres
    const workshopsGuests = await getAllWorkshopsGuests(userWorkshops)
    dispatch(set_WorkshopGuests_AtStore(workshopsGuests))
  }
}

//States updates actions
export const updateUsersState = (users, updatedUser, action) => {
  return async dispatch => {
    const updatedUsers = { ...users };
    //debugger
    //Se crea un nuevo objeto porque si se trata de modificar el objeto user o alguno de sus atributos ...
    //creará un conflicto con REDUX!

    if (action === 'EDIT') {

      updatedUsers.rows = findAndReplace(updatedUser, users.rows)

    } else if (action === 'IS_ACTIVE') {
      updatedUsers.rows = users.rows.map(user => {

        const newUser = { ...user }
        if (user.id === updatedUser.id) {
          newUser.attributes.is_active = !user.attributes.is_active;
          return newUser;
        }

        return user;
      })
    }

    dispatch(setTotalUsersAtStore(updatedUsers))
  }
}

export const updateGuestsState = (guests, updatedGuest, total_guests, action) => {
  return async dispatch => {

    let updatedGuests = { ...guests }
    let updated_total_guests = [...total_guests]

    //debugger
    if (action === 'EDIT') {

      updatedGuests.rows = findAndReplace(updatedGuest, guests.rows)
      updated_total_guests = findAndReplace(updatedGuest, total_guests)

    } else if (action === 'DELETE') {

      updatedGuests.rows = updatedGuests.rows.filter(guest => {
        return guest.id !== updatedGuest.id
      })
    }

    //debugger
    //setGuestAtStore es para setear solo los invitados del usuario
    dispatch(setGuestsAtStore(updatedGuests))

    //setTotalGuestsAtStore es para setear todos los invitados del usuario
    dispatch(setTotalGuestsAtStore(updated_total_guests))
  }
}

export const updateWorkshopsState = (params) => {
  const { total_workshops } = params
  const { updatedWorkshop, action } = params

  return async dispatch => {
    let updatedWorkshops = [...total_workshops];

    //Se crea un nuevo array porque si se trata de modificarlo se creará un conflicto con REDUX!...

    if (action === 'CREATE') {
      updatedWorkshops.concat(updatedWorkshop)

    } else if (action === 'EDIT') {
      //updatedWorkshop es una array y debe ser tratado con un elemento
      updatedWorkshops = findAndReplace(updatedWorkshop[0], total_workshops)
    }

    //setWorkshopsAtStore es para settear todos los workshops creados por el  usuario
    dispatch(setWorkshopsAtStore(updatedWorkshops))
  }
}