import React from 'react';
import {Alert, Button} from 'reactstrap';
import InputModal from '../../widgets/inputModal';

const VerifyUserForm = (props) =>{
  const {loading, registerSuccess, registrationError} = props
  const {setRef, handleSubmit, handleInvalid, handleChange, user} = props

  return(
    <>
      <h1>Verificación de Usuario</h1>
      <form
        className="needs-validation"
        ref={setRef}
        onSubmit={handleSubmit}
        onInvalid={handleInvalid}
      >
        <div className="Register__body">
          {
            registerSuccess && 
            <Alert color="success">
              Verificación de usuario con éxito!
            </Alert>
          }
          {
            registrationError && 
            <Alert color="danger">
              Acceso invalido!
            </Alert>
          }
          
          <InputModal
            id="email"
            type="email"
            title="Email"
            autoComplete="email"
            className="form-control"
            placeholder="Ingresa tu email"
            handleChange={handleChange}
            defaultValue={user.email}
          />
          <InputModal
            id="password"
            type="password"
            title="Contraseña"
            autoComplete="password"
            className="form-control"
            placeholder="Ingresa tu contraseña"
            handleChange={handleChange}
            defaultValue={user.password}
          />
          <div className="submit-btn-area">
            <Button disabled={loading} color="primary" className="btn btn-primary" type="submit">
              {loading ? 'Cargando...' : 'Submit'}
            </Button>
          </div>  
        </div>
      </form>
    </>
  )
}
export default VerifyUserForm;