import React, { useState } from 'react'
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { setLoading, setWorkshopsAtStore, set_WorkshopGuests_AtStore, updateGuests, updateLoggedUser } from '../../store/actions'
import messages from './utils/statusMessages.json'
import Layout from '../../layout'
import WidthLimit from '../../layout/widthLimit';
import WorkshopModal from './Modal/workshopModal'
import WorkshopAlert from './Widgets/workshopAlert';
import Button from '../../widgets/button';
import WorkshopsCreated from './workshopsCreated'
import { available_sessions_alert, due_dateExpiredAlert } from '../../helpers/messageAlert';
import Card from 'reactstrap/lib/Card';
import WorkshopFilter from './workshopFilter';
import { deleteWorkshop, updateUser } from '../../helpers/sendData';
import { objectToSend } from '../../widgets/utils/objectToSend';
import { useInfiniteSessions, useSessionsLeft } from '../../hooks/useInfiniteSessions';
import { shortDate, shortTime } from '../../utils/parseDate/formatDisplay';
import { getTodayUTC } from '../../utils/parseDate/getValues';

const Workshops = (props) => {
  const { user, loading, dispatch, workshopsGuests, userAllAttributes } = props

  const GREATER_THAN_INFINITE_SESSIONS = useInfiniteSessions(user.available_sessions)
  debugger
  const SESSIONS_LEFT = useSessionsLeft(user.available_sessions)
  const sessions_max_duration = user?.plan?.attributes?.sessions_max_duration
  const today = getTodayUTC()

  //STATE
  const workshopInitialState = {
    id: "",
    name: "",
    date: "",
    start: "",
    end: "",
    has_expiration: !GREATER_THAN_INFINITE_SESSIONS
  }

  const [workshop, setWorkshop] = useState(workshopInitialState)

  const [sortedWorkshops, setSortedWorkshops] = useState([])
  const [search, setSearch] = useState(false)

  const [state, setState] = useState({
    modalVisible: false,
    sweetAlertVisible: false,
    update: false,
    guests: [],
    workshopToDelete: null,
  })

  const [notification, setNotification] = useState({
    state: false, color: '', message: '',
  })

  //FUNCTIONS
  const notiActions = {
    setAll: (state, message, color) => {
      setNotification({ state, color, message })
    },
    resetAll: () => {
      setNotification({ state: false, color: '', message: '' })
    }
  }

  const handleModal = {
    open: () => {
      notiActions.resetAll()

      const openModal = () => {
        setState({
          modalVisible: true,
          update: false,
          guests: [],
        })
      }

      if (user.is_admin) {

        openModal()

      } else {

        //Evalúa si el el usuario tiene plan asignado
        if (user.plan !== 'N/A') {

          if (user.available_sessions > 0) {

            openModal()

          } else {
            available_sessions_alert()
          }

        } else {

          alert('No tienes ningun plan asignado')
        }
      }
    },
    close: () => {
      setState({ modalVisible: false })
      setWorkshop(workshopInitialState)
    }
  }

  const handleWorkshop = {
    update: (workshop, guests) => {
      if (user.available_sessions > 0) {

        const today = getTodayUTC()
        const { id, attributes: { name, start, end, has_expiration } } = workshop;
  
        if (guests.length) {
          guests = guests.map(guest => guest.attributes.email)
          console.log(guests)
        }
  
        notiActions.resetAll()
  
        //se abre workshopModal y se le pasa update y workshop
        setState({
          modalVisible: true,
          update: true,
          guests,
        })
  
        setWorkshop({
          id,
          name,
          date: (today > start) ? "" : shortDate(start),
          start: shortTime(start),
          end: shortTime(end),
          expired: today > end,
          has_expiration
        })

      } else {
        available_sessions_alert()
      }
    },
    delete: (workshop) => {
      // if (user.available_sessions > 0) {
        setState({
          sweetAlertVisible: true,
          workshopToDelete: workshop
        })
      // } else {
        // available_sessions_alert()
      // }
    }
  }

  const handleDelete = {
    confirm: async () => {
      const { workshopToDelete } = state
      const { available_sessions } = user

      const { created_at, updated_at, plan, ...rest } = userAllAttributes.attributes

      try {
        await deleteWorkshop(workshopToDelete.id)

        //Si el usuario es un admin pues esto no ejecuta
        if (!user.is_admin) {

          const userBody = objectToSend("users", {
            ...rest,
            available_sessions,
          })

          const { attributes: updatedUser } = await updateUser(user.id, userBody)
          
          debugger
          
          dispatch(updateLoggedUser({
            id: user.id,
            attributes: { ...updatedUser, plan }
          }))
        }

        const newWorkshops = user.workshops.filter(workshop => {
          return workshop.id !== workshopToDelete.id
        })

        const newWorkshopsGuests = workshopsGuests.filter(guests => {
          if (guests.length)
            return guests[0].id !== workshopToDelete.id

          return !!guests;
        })

        dispatch(setWorkshopsAtStore(newWorkshops))
        dispatch(set_WorkshopGuests_AtStore(newWorkshopsGuests))

        alert("Se ha eliminado el Taller")
        notiActions.setAll(true, messages.successDelete, 'success')
        handleHideAlert()

      } catch (error) {
        notiActions.setAll(true, messages.errorDelete, 'danger')
        handleHideAlert()
      }
    },
    cancel: () => handleHideAlert()
  }

  const handleHideAlert = () => {
    setState({
      sweetAlertVisible: false,
      workshopToDelete: null
    })
  }

  const updateWorkshops = async () => {
    dispatch(setLoading(true))

    try {
      await dispatch(updateGuests(user))
      alert('Se han actualizado los talleres')
      dispatch(setLoading(false))

    } catch (error) {
      console.log(error)
      console.log(error.message)
      dispatch(setLoading(false))
    }
  }
  
  //END-FUNCTIONS

  if (!user.is_admin && today > user.due_date) {
    due_dateExpiredAlert()
    return <Redirect to="/suscripciones" />
  }

  const { update, guests } = state;
  const { modalVisible, sweetAlertVisible } = state;

  return (
    <Layout>
      <WidthLimit>
        <div className="width-limit__container no-shadow">
          <Card className="workshops-section px-2 px-sm-4 py-4">
            <div className="d-flex flex-column flex-sm-row justify-content-between">
             
              <h3>Sesiones</h3>
              
              <div className="d-flex justofy-content-center">
                
                <Button
                  text="Crear sesión"
                  color="success mr-3"
                  className="flex-fill"
                  handleClick={handleModal.open}
                />
                
                <Button
                  text="Actualizar"
                  color="success"
                  className="flex-fill"
                  loading={loading}
                  handleClick={updateWorkshops}
                />
                
              </div>
            </div>

            {
              !user.is_admin &&
              <>
                <span>
                  Tienes <strong>{SESSIONS_LEFT}</strong> sesiones disponibles
                </span>
                <span>
                  Máximo de horas por sesión: <strong>{sessions_max_duration}</strong> horas
                </span>
              </>
            }

            <WorkshopFilter
              workshops={user.workshops}
              sortedWorkshops={sortedWorkshops}
              search={search}
              setSearch={setSearch}
              setSorted={setSortedWorkshops}
            />

            <WorkshopsCreated
              user={user}
              search={search}
              notification={notification}
              handleWorkshop={handleWorkshop}
              sortedWorkshops={sortedWorkshops}
              resetNotification={notiActions.resetAll}
            />

          </Card>
        </div>
      </WidthLimit>

      {
        modalVisible &&
        <WorkshopModal
          update={update}
          guests={guests}
          workshop={workshop}
          handleCloseModal={handleModal.close}
          setNotification={notiActions.setAll}
        />
      }

      {
        sweetAlertVisible &&
        <WorkshopAlert handleDelete={handleDelete} />
      }
    </Layout>
  )
}

const mapStateToProps = (state) => {
  const { Login: { user, loading } } = state
  const { id, attributes: { is_admin, plan, due_date, available_sessions } } = user
  const { Workshops: { workshopsGuests, total_workshops } } = state

  return ({
    loading,
    workshopsGuests,
    user: {
      id,
      is_admin,
      plan,
      due_date,
      available_sessions,
      workshops: total_workshops,
    },
    userAllAttributes: user
  })
}
export default connect(mapStateToProps)(Workshops);
