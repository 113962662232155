import React from 'react';
import { findWorkshopGuests } from './utils/workshopsUtils';
import WorkshopListItem from './workshopListItem';
import NoResults from '../../widgets/noResults';

const WorkshopsList = ({workshops = [], workshopsGuests, handleWorkshop, noResultMessage}) => {
  return (
    <>
      {
        workshops.length ?
          workshops.map((workshop, index) => (
            <WorkshopListItem
              key={index}
              workshop={workshop}
              workshopGuests={findWorkshopGuests(workshop.id, workshopsGuests)}
              handleWorkshop={handleWorkshop}
            />
          ))
        :
          <NoResults>
            {noResultMessage}
          </NoResults>
      }
    </>
  )
}

export default WorkshopsList
