import axios from "axios";
import { API_AFIP } from "./apiURLs";


export const getVoucherTypes = async () => {
    const { data } = await axios.get(`${API_AFIP}/voucherTypes`)
    return data;
}

export const getSalesPoints = async () => {
    const { data } = await axios.get(`${API_AFIP}/SalesPoints`)
    return data;
}

export const getDocumentTypes = async () => {
    const { data } = await axios.get(`${API_AFIP}/documentTypes`)
    return data;
}

export const getBillings = async (params) => {
    const { data } = await axios.get(`${API_AFIP}/billings`, { params })
    return data;
}

export const createBilling = async (body) => {
    const { data } = await axios.post(`${API_AFIP}/createCAE`, body)
    return data;
}

