const INFINITE_LIMIT_SESSIONS = 25000

export const useInfiniteSessions = (available_sessions) => {
  return available_sessions > INFINITE_LIMIT_SESSIONS
}

export const useSessionsLeft = (available_sessions) =>{
  debugger
  const GREATER_THAN_INFINITE_SESSIONS = useInfiniteSessions(available_sessions)
  return GREATER_THAN_INFINITE_SESSIONS ? "" : available_sessions;
}