import React, {Component} from 'react';
import {Alert, Button} from 'reactstrap';
import InputModal from '../../widgets/inputModal';
import {withRouter} from 'react-router-dom';
import RegisterLayout from '../../layout/registerLayout';
import {getUserByEmail} from '../../helpers/getDashboardData';
import {resetPasswordLink} from '../../widgets/utils/qaLinks';
import {resetPasswordEmail} from '../../helpers/sendEmails';
import {messageToSend} from '../../widgets/utils/objectToSend';

class ForgetPassword extends Component {

  state = {
    userEmail: '',
    loading: false,
    registerSuccess: false,
    registrationError: false,
    errorMessage: ''
  }
  
  handleSubmit = async(event) =>{
    event.preventDefault()
    this.setLoading(true)
    const {userEmail} = this.state
    
    try {
      const user = await getUserByEmail(userEmail)
      const {name, email} = user.attributes   
      
      const attributes = {
        subject: "Recuperación de contraseña",
        user_name: name,
        reset_url: resetPasswordLink(user.id)
      }
      
      const message = messageToSend('reset_password', email, attributes)
      
      try {
        await resetPasswordEmail(message)
        this.setLoading(false, 'success')
      
      } catch (error) {
      
        console.log(error)
        this.setLoading(false, 'error', 'Ha habido un error con el envio del correo')
      }

    } catch (error) {
    //debugger
      console.log(error)
      this.setLoading(false, 'error', 'el correo no coincide con ninguna cuenta')
    } 
  }
  handleInvalid = () =>{
    this.form.classList.add('was-validated')
  }
  
  handleChange = ({target}) =>{
    this.setState({
      [target.name]: target.value
    })
  }
  
  setLoading = (loading, state, errorMessage) =>{
    this.setState({
      registerSuccess: false,
      registrationError: false,
    })
    if(state === 'success'){
      this.setState({
        loading,
        registerSuccess: true
      })
    }else if(state === 'error'){
      this.setState({
        loading,
        registrationError: true,
        errorMessage,
      })
    }else{
      this.setState({ loading })
    }
  }
  
  setRef = element =>{
    this.form = element
  }
  
  render() {
    const { registerSuccess, registrationError, loading, errorMessage } = this.state
    return (
      <RegisterLayout>
        <h1>Reestablecer contraseña</h1>
        <form 
          className="needs-validation"
          ref={this.setRef}
          onSubmit={this.handleSubmit} 
          onInvalid={this.handleInvalid}
        >
          <div className="Register__body">
            
            {
              registerSuccess && 
              <Alert color="success">
                Se le ha enviado un correo con el link para Reestablecer su constraseña
              </Alert>
            }
            {
              registrationError && 
              <Alert color="danger">
                {errorMessage}
              </Alert>
            }
            <InputModal
              id="userEmail"
              type="email"
              title="Email"
              autoComplete="email"
              className="form-control"
              placeholder="Ingresa el correo electrónico asociado a tu cuenta"
              handleChange={this.handleChange}
              defaultValue={this.state.userEmail}
            />
            
            <div className="submit-btn-area">
              <Button disabled={loading} color="primary" className="btn btn-primary" type="submit">
                {loading ? 'Loading ...' : 'Enviar'} <i className="ti-arrow-right"></i>
              </Button>
            </div>
            
          </div>
        </form>
      </RegisterLayout>
    );
  }
}

export default withRouter(ForgetPassword);

