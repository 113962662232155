import React, { Component, useRef, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { bindActionCreators } from 'redux';
import RegisterLayout from '../../layout/registerLayout';
import StatusModal from './statusModal';
import { Link } from 'react-router-dom';
import InputModal from '../../widgets/inputModal';
import { Button, Alert } from 'reactstrap';

const Login = (props) => {

  const [loading, setLoading] = useState(false)

  const [statusModalVisible, setStatusModalVisible] = useState(true)

  const [user, setUser] = useState({
    email: "",
    password: "",
    statusModalVisible: true
  })
  
  const $form = useRef(null)
  
  const handleSubmit = (event) => {
    event.preventDefault()
    setLoading(true)

    props.actions.checkLogin()
    props.actions.getUser(user.email, user.password, props.history);
  }

  const handleChange = ({ target }) => {
    setUser({
      ...user,
      [target.name]: target.value
    })
  }

  const { is_logged } = props
  const { loginError, known_error, login_success, messageErrorLogin } = props

  return (
    <div>
      {
        is_logged ?
          <Redirect to="/dashboard" />
          :
          <RegisterLayout>
            <form
              className="needs-validation"
              onSubmit={handleSubmit}
              onInvalid={() => {
                $form.current.classList.add('was-validated')
              }}
            >
              <div className="Login__body">

                {/* LOGIN MESSAGE */}
                <div>
                  {
                    login_success &&
                    <Alert color="success">
                      Tu Login es exitoso, espere mientras se carga la sesión...
                    </Alert>
                  }
                  {
                    loginError &&
                    <Alert color="danger">
                      {known_error ? messageErrorLogin : 'Error, no se pudo iniciar sesion'}
                    </Alert>
                  }
                </div>

                {/* EMAIL | PASSWORD */}
                <div className="LoginForm">
                  <InputModal
                    id="email"
                    type="email"
                    title="Correo electrónico"
                    className="form-control"
                    handleChange={handleChange}
                    defaultValue={user.email}
                    required={true}
                  />

                  <InputModal
                    id="password"
                    type="password"
                    title="Contraseña"
                    className="form-control"
                    handleChange={handleChange}
                    defaultValue={user.password}
                    required={true}
                  />
                </div>

                {/* SUBMIT BUTTON */}
                <div className="submit-btn-area">
                  <Button
                    color="primary"
                    className="btn btn-primary"
                    type="submit"
                    disabled={loading && !loginError}
                  >
                    {
                      (loading && !loginError) ? 'Cargando...' : 'Ingresar'
                    }
                    <i className="ti-arrow-right"></i>
                  </Button>
                </div>

                <div className="form-footer text-right">
                  <p className="text-muted m-0">
                    <Link to="/resetPassword">
                      <i className="mdi mdi-lock"></i> ¿Olvidaste tu contraseña?
                    </Link>
                  </p>
                </div>

              </div>
            </form>
          </RegisterLayout>
      }
      {
        statusModalVisible &&
        <StatusModal handleClose={() => setStatusModalVisible(false)} />
      }
    </div>
  );
}

const mapStatustoProps = ({ Login }) => {
  const { loginError, loading, login_success, is_logged, known_error, messageErrorLogin } = Login
  return {
    loginError,
    loading,
    login_success,
    is_logged,
    known_error,
    messageErrorLogin
  };
}
const mapDispatchtoProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
};

export default withRouter(connect(mapStatustoProps, mapDispatchtoProps)(Login));