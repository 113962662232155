import React, { useRef } from 'react'
import Checkbox from '../../widgets/checkbox';
import InputModal from '../../widgets/inputModal';
import OptionalFieldInput from '../../widgets/optionalFieldInput';
import FormFooterButtons from './formButtons';
import { paintCurrencyTypes } from './utils/currencyType';

const EditPlansForm = (props) => {

  const { handleForm, plan } = props
  const { INFINITE_SESSIONS, stringLimit, loading } = props

  const form = useRef(null)

  const handleInvalid = () => {
    form.current.classList.add('was-validated')
  }

  const limitHours = new Array(24).fill(0)

  return (
    <form
      ref={form}
      className="needs-validation pb-4"
      onSubmit={handleForm.submit}
      onInvalid={handleInvalid}
    >
      <InputModal
        id="name"
        title="Nombre"
        type="text"
        defaultValue={plan.name}
        handleChange={handleForm.change}
        className="form-control"
        required={true}
      />

      <InputModal
        id="description"
        type="text"
        title={() => <> Descripción <span className="info">(caracteristicas separadas por guiones "-")</span> </>}
        defaultValue={plan.description}
        handleChange={handleForm.change}
        className="form-control"
        validation={stringLimit}
        validationMessage="No se pueden ingresar más de 512 caracteres"
        required={true}
      />

      <InputModal
        id="guests_limit"
        title="Cant. de Invitados"
        type="number"
        min="1"
        max="99"
        maxLength="2"
        // pattern="([0-9]{2})" 
        defaultValue={plan.guests_limit}
        handleChange={handleForm.change}
        className="form-control"
        required={true}
      />

      <div className="dias_plan">
        <label htmlFor="scope_days">
          Días disponibles del plan
          <select
            name="scope_days"
            id="scope_days_select"
            value={plan.scope_days}
            onChange={handleForm.change}
            className="form-control"
            required
          >
            <option value="">Seleccione...</option>
            <option value="1">Diario</option>
            <option value="7">Semanal</option>
            <option value="15">Quincenal</option>
            <option value="30">Mensual 30 dias</option>
            {/* <option value="31">Mensual 31 dias</option> */}
            <option value="365">Anual</option>
            <option value="90">Trimestral</option>
            {/* <option value="92">Trimestral 2</option> */}
            <option value="60">Bimestral 1</option>
            {/* <option value="61">Bimestral 2</option> */}
            <option value="180">Semestral</option>
          </select>
        </label>

        <input
          type="number"
          min="1"
          max="999"
          maxLength="3"
          disabled
          value={plan.scope_days}
          name="scope_days"
          id="scope_days"
          onChange={handleForm.change}
          className="form-control"
          required
        />
      </div>

      <OptionalFieldInput
        condition={!plan.is_sessions_limitless}
        checkbox={{
          id: "is_sessions_limitless",
          checked: plan.is_sessions_limitless,
          title: "Sesiones ilimitadas",
          handleChange: handleForm.change,
        }}
        optionalInput={() => (
          <InputModal
            id="sessions_limit"
            title="Cant. de Sesiones"
            type="number"
            min="1"
            max={INFINITE_SESSIONS}
            defaultValue={plan.sessions_limit}
            handleChange={handleForm.change}
            className="form-control"
            required={true}
          />
        )}
      />

      <OptionalFieldInput
        condition={!plan.is_free}
        checkbox={{
          id: "is_free",
          checked: plan.is_free,
          title: "Plan Gratis",
          handleChange: handleForm.change,
        }}
        optionalInput={() => (
          <InputModal
            id="price"
            type="number"
            min="1"
            title="Precio"
            pattern="^\d+(?:\.\d{1,2})?$"
            maxLength="8"
            defaultValue={plan.price}
            handleChange={handleForm.change}
            className="form-control"
            required={true}
          />
        )}
      />
      
      <div className="flex pt-3">
        <label className="w-50 pr-4" htmlFor="currency">
          Tipo de moneda
          <select
            name="currency"
            value={plan.currency}
            onChange={handleForm.change}
            className="form-control"
            required
          >
            <option value="">Seleccione...</option>
            {paintCurrencyTypes()}
          </select>
        </label>

        <label className="w-50" htmlFor="sessions_max_duration">
          Máximo de horas permitidas por sesión
          <select
            name="sessions_max_duration"
            value={plan.sessions_max_duration}
            onChange={handleForm.change}
            className="form-control"
            required
          >
            {
              //number is useless, we just need the index
              limitHours.map((number, i) => {
                const value = (i) => i + 1;
                return (
                  <option key={i} value={value(i)}>
                    {value(i)}
                  </option>
                )
              })
            }

          </select>
        </label>
      </div>

      <Checkbox
        id="is_renewable"
        checked={plan.is_renewable}
        title="Plan Renovable"
        handleChange={handleForm.change}
      />

      <FormFooterButtons loading={loading} />
    </form>
  )
}

export default EditPlansForm