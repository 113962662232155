import React, { useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { Card, Alert as UserInfo } from 'reactstrap';
import { renewPaypalPlan, saveAccess_token } from '../../api/paypal';
import { updateUser } from '../../helpers/sendData';
import { useSessionsLeft } from '../../hooks/useInfiniteSessions';
import { logError } from '../../widgets/utils/error';
import { objectToSend } from '../../widgets/utils/objectToSend';
import { currencyType } from '../Plans/utils/currencyType';
import NewPlanCard from './newPlanCard';
import PlanButton from './planButton';
import { getNewDueDate } from './utils/getNewDueDate';

const PlanChanging = ({ user, newPlan, handleClose }) => {

  const { name: userName, plan: userPlan, available_sessions } = user.attributes

  const [visibleWarning, setVisibleWarning] = useState(false)
  const [warningMessage] = useState("¿Estas seguro de que quieres cambiar tu plan?")

  const SESSIONS_LEFT = useSessionsLeft(available_sessions)

  const getFormat = () => {
    const { scope_days, sessions_limit } = newPlan.attributes
    const { due_date, updated_at, created_at, plan_id, plan, available_sessions, ...rest } = user.attributes

    const attributes = {
      ...rest,
      is_active: true,

      previous_plan_id: plan_id,
      previous_due_date: due_date,
      previous_available_sessions: available_sessions,

      plan_id: newPlan.id,
      due_date: getNewDueDate(scope_days),
      available_sessions: sessions_limit,
    }

    const body = objectToSend('users', attributes)

    return body;
  }

  const handleConfirm = async () => {
    const { dolar, pesoArgentino } = currencyType;
    const { currency, payment_renewal_url, payment_url, price, is_free } = newPlan.attributes;

    let payment_page = "";

    const body = getFormat();

    debugger

    try {
      //The plan selection panel gets closed
      setVisibleWarning(false)
      handleClose()
      
      if(is_free){
        
        localStorage.clear()
        await updateUser(user.id, body)
        payment_page = payment_url;
        
      }else{
        
        if (currency === dolar.code) {
          //No es necesario cerrar la sesión, se actualiza el usuario después del pago
          payment_page = await renewPaypalPlan(newPlan, body, price);
  
        } else if (currency === pesoArgentino.code) {
          //Se cierra la sesión y se actualiza el usuario
          alert('¡Felicidades, acabas de cambiar tu plan, a continuación se cerrará la sesión y se te enviará a concretar el pago.')
  
          localStorage.clear()
          await updateUser(user.id, body)
          payment_page = payment_renewal_url;
        }
      }

      window.location = payment_page;

    } catch (error) {
      alert("Ha ocurrido un error con la actualización de su plan")
      logError(error)
    }
  }

  return (
    <div className="planChanging">
      <Card className="planChanging-container p-2 pb-3 p-md-4 mb-5 card">
        
        <div className="user-info">
          <div className="user-info_container p-4">
            <h4 className="card_title">Datos del Usuario</h4>
            <UserInfo color="secondary">
              Hola {userName}
            </UserInfo>

            <UserInfo color="info">
              Este es tu plan actual: {userPlan.attributes.name}
            </UserInfo>
          </div>
        </div>

        <div className="newPlan text-center">
          <div className="newPlan-container">
            <NewPlanCard
              key={1}
              type={'Plan Actual'}
              plan={userPlan.attributes}
            />
            <NewPlanCard
              key={2}
              type={'Plan Nuevo'}
              plan={newPlan.attributes}
            />
          </div>
          
          {
            !!SESSIONS_LEFT &&  
            <div className="bg-white border border-danger pz-4 py-3 text-danger">
              <p className="m-0">
                Aún tienes <strong>{SESSIONS_LEFT}</strong> {" "}
                sesiones disponibles. Si actualizas el plan las sesiones se reiniciaran
              </p>
            </div>
          }

          <div className="buttons-container">
            <PlanButton
              name={'Aceptar'}
              type={'btn btn-secondary'}
              handleClick={() => setVisibleWarning(true)}
            />
            <PlanButton
              name={'Cerrar'}
              type={'btn btn-danger'}
              handleClick={handleClose}
            />
          </div>
        </div>
        
      </Card>

      {
        // Le pregunta al usuario si está seguro de querer borrar el plan
        visibleWarning &&
        <SweetAlert
          title={warningMessage}
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={handleConfirm}
          onCancel={() => setVisibleWarning(false)}
        >
          No se puede revertir!
        </SweetAlert>
      }
      
    </div>
  )
}
export default connect()(PlanChanging);