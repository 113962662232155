import React, { useRef } from 'react';
import InputModal from '../../../widgets/inputModal';
import InputDateModal from '../../../widgets/inputDateModal';
import FooterModal from '../../../widgets/footerModal';
import Button from '../../../widgets/button';
import { clearBothTimes, warningTime } from '../utils/workshopsUtils';
import { timeMessages } from '../utils/statusMessages';

//Components
import Modal from '../../../widgets/modal';

//Actions
import { setLoading, updateLoggedUser } from '../../../store/actions'
import { updateGuests, updateWorkshopsState } from '../../../store/actions';

//helpers
import { createWorkshop, updateUser, updateWorkshop } from '../../../helpers/sendData';

//Utils
import { getGuestsAccessCode } from '../utils/createInvitation';
import messages from '../utils/statusMessages';
import { getEmailsCount, displayNotification } from '../utils/sendWorkshopEmails';
import { getWorkshopObject } from '../utils/getWorkshopObject';
import WorkshopAddGuests from './workshopAddGuests';
import InputCheck from '../../../widgets/inputCheck';
import { objectToSend } from '../../../widgets/utils/objectToSend';
import { useInfiniteSessions } from '../../../hooks/useInfiniteSessions';
import { getTodaysDate, getTodayUTC } from '../../../utils/parseDate/getValues';
import { isNot_a_FutureDay } from '../../../utils/parseDate/validation';
import { getDateFrom } from '../../../utils/parseDate/getValues';
import { differenceInHours } from 'date-fns';

// import { setWorkshopEmail } from '../utils/setWorkshopsEmail';

const WorkshopFeatures = (props) => {
  const { workshop, loading, user, userAllAttributes, emails, update } = props
  const { setWorkshop, handleCloseModal } = props

  const { total_workshops } = props;
  const { dispatch, sendEmails } = props;
  const { setNotification, setUpdate, setEmails } = props;

  const GREATER_THAN_INFINITE_SESSIONS = useInfiniteSessions(user.available_sessions)

  const modal = useRef(null)
  const startInput = useRef(null)
  const endInput = useRef(null)

  const handleTimeChange = time => {
    const start = startInput.current.value;
    const end = endInput.current.value;

    const { startBiggerThanEnd, startEqualToEnd, maxDurationExceeded } = timeMessages;

    const inputTime = getDateFrom(workshop.date, time.value).toISOString()

    const currentTime = getTodayUTC()

    //valida si la fecha ingresada aún no ha pasado
    if (inputTime > currentTime) {

      const setTime = () => {
        setWorkshop({
          ...workshop,
          [time.name]: time.value,
        })
      }

      //Si han ingresado los dos tiempos
      if (start && end) {
        if (start > end) {

          warningTime(startBiggerThanEnd)

        } else if (start === end) {

          warningTime(startEqualToEnd)

        } else {
          const { sessions_max_duration } = user.plan.attributes
          
          if (!user.is_admin || sessions_max_duration === 0) {

            const startTime = getDateFrom(workshop.date, start)
            const endTime = getDateFrom(workshop.date, end)

            const timeDifference = differenceInHours(endTime, startTime, { roundingMethod: "ceil" })

            if (timeDifference > sessions_max_duration) {

              const warningMessage = `${maxDurationExceeded} (${sessions_max_duration} horas)`
              warningTime(warningMessage)

              setWorkshop({ ...workshop, end: "" })

            } else {
              setTime()
            }
          } else {
            setTime()
          }
        }

      } else {
        setWorkshop({
          ...workshop,
          [time.name]: time.value,
        })
      }

    } else {
      clearBothTimes(workshop, setWorkshop)
    }
  }

  const handleChange = ({ target: input }) => {
    const action = input.name

    const handleTime = () => {
      handleTimeChange(input)
    }

    const handleDefault = () => {
      setWorkshop({
        ...workshop,
        [input.name]: input.value
      })
    }

    const inputs = {
      name: handleDefault,
      start: handleTime,
      end: handleTime,
      date: () => {
        if (isNot_a_FutureDay(input.value)) {

          alert('ese dia ya paso')
          input.value = workshop.date

        } else {
          handleDefault()
        }
      },
      has_expiration: () => {
        setWorkshop({
          ...workshop,
          has_expiration: input.checked,
          end: input.checked ? workshop.end : ""
        })
      }
    }

    inputs[action]();
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(setLoading(true))

    let workshopAction = "";
    let createdWorkshop = { id: "" };

    const workshopsToBeSaved = []

    const currentTime = getTodayUTC()

    // Primero se verifica se si se añadieron invitados a las sesión
    if (emails.length) {
      const { workshopObject, workshopInfo } = getWorkshopObject(workshop)
      const { start, end } = workshopInfo

      console.log(workshopObject)

      if (start > currentTime && end > currentTime) {
        try {
          debugger
          if (!update) {
            createdWorkshop = await createWorkshop(workshopObject)
            workshopAction = 'CREATE';
          } else {
            createdWorkshop = await updateWorkshop(workshopObject, workshop.id)
            workshopAction = 'EDIT';
          }

          workshopsToBeSaved.push(createdWorkshop)

          //Crea los invitados que no hayan sido registrados y devuelve los codigos de acceso al taller de c/u de ellos
          const guests = await getGuestsAccessCode(createdWorkshop.id, emails)

          const emailsCount = getEmailsCount(guests)
          let emailsSent = 0;

          const { sign_in_code } = createdWorkshop.attributes

          //Envia todos los corres a los invitados y devuelve cuántos se enviaron correctamente
          emailsSent = await sendEmails(guests, emailsSent, workshopInfo, sign_in_code)

          //Si el usuario es un constelador se le resta 1 de "available_sessions"
          if (!user.is_admin) {
            const { created_at, updated_at, plan, available_sessions, ...rest } = userAllAttributes.attributes

            const attributes = { ...rest, available_sessions: (available_sessions - 1) }
            const userBody = objectToSend("users", attributes)

            const { attributes: updatedUser } = await updateUser(user.id, userBody)
            
            debugger
            
            dispatch(updateLoggedUser({
              id: user.id,
              attributes: { ...updatedUser, plan }
            }))
          }

          //Notifica si se enviaron los correos correctamente o no
          displayNotification(emailsCount, emailsSent)

        } catch (error) {
          console.log(error)
          console.log(error.message)

          dispatch(setLoading(false))

          if (error.message === "Network Error") {
            alert('Error de conexión, revise su conexión a internet')
          } else {
            alert(messages.createErrorMssg)
          }
          setNotification(true, messages.errorUpdate, 'danger')
        }
      } else {
        clearBothTimes(workshop, setWorkshop)
      }

      //Actualizar los workshops e invitados
      try {
        if (workshopAction) {
          const params = { total_workshops, workshopsToBeSaved, workshopAction }
          setNotification(true, messages.successUpdate, 'success')
          dispatch(updateWorkshopsState(params))
          dispatch(updateGuests(user))
        }

      } catch (error) {
        console.log(error)
        console.log(error.message)
      }

      handleCloseModal()

    } else {
      alert("No ha añadido ningún invitado")
    }

    dispatch(setLoading(false))
  };

  const handleInvalidSubmit = () => {
    modal.current.classList.add('was-validated')
  }

  const repeatWorkshop = () => {
    setUpdate(false)
    setWorkshop({
      ...workshop,
      date: getTodaysDate()
    })
  }

  const title = update ? (workshop.expired ? "Actualizar" : "Editar") : "Crear";

  return (
    <Modal
      setRef={modal}
      title={`${title} Sesión`}
      handleSubmit={handleSubmit}
      handleInvalidSubmit={handleInvalidSubmit}
    >
      <InputModal
        id="name"
        type="text"
        title="Nombre de la Sesión"
        className="form-control"
        handleChange={handleChange}
        defaultValue={workshop.name}
      />

      <div className={`workshop-time ${workshop.has_expiration ? "" : "no-workshop-end"}`}>

        <InputDateModal
          id="date"
          type="date"
          title="Fecha:"
          className="form-control"
          defaultValue={workshop.date}
          handleChange={handleChange}
        />

        <InputDateModal
          id="start"
          type="time"
          title="Inicia a las:"
          className="form-control"
          defaultValue={workshop.start}
          setRefInputs={startInput}
          handleChange={handleChange}
        />

        <InputDateModal
          id="end"
          type="time"
          title="Termina a las:"
          labelClassName={workshop.has_expiration ? "" : "d-none"}
          className="form-control"
          defaultValue={workshop.end}
          setRefInputs={endInput}
          required={workshop.has_expiration}
          handleChange={handleChange}
        />

      </div>

      <WorkshopAddGuests
        emails={emails}
        setEmails={setEmails}
      />

      {
        // El usuario sólo puede usar sesiones infinitas si su plan lo permite
        // Si el usuario es un admin sí puede utilizar sesiones infinitas
        GREATER_THAN_INFINITE_SESSIONS &&
        <InputCheck
          id="has_expiration"
          title="Sesión con fecha límite"
          containerClass="m-0"
          checked={workshop.has_expiration}
          handleChange={handleChange}
        />
      }


      <FooterModal>
        {
          update &&
          <Button
            color="dark"
            notLoading
            handleClick={repeatWorkshop}
            loading={loading}
            text="Repetir"
          />
        }

        <Button
          type="submit"
          color={workshop.expired ? "info" : "success"}
          loading={loading}
          text={workshop.expired ? "Actualizar" : "Guardar"}
        />

        <Button
          text="Cerrar"
          color="danger"
          notLoading
          loading={loading}
          handleClick={handleCloseModal}
        />
      </FooterModal>
    </Modal>
  )
}

export default WorkshopFeatures
