import React, { useState } from 'react';
import { connect } from 'react-redux';
import Trash from '../../widgets/icons/trash';
import Edit from '../../widgets/icons/edit';
import WorkshopGuests from './workshopGuests';
import { filterGuests } from './utils/workshopsUtils';
import VisibleEye from '../../widgets/icons/visibleEye';
import HiddenEye from '../../widgets/icons/hiddenEye';
import WorkshopItemInfo from './workshopItemInfo';
import Button from '../../widgets/button';
import ExpirationBadge from './Widgets/expirationBadge';
import { getTodayUTC } from '../../utils/parseDate/getValues';

const WorkshopListItem = ({ workshop, handleWorkshop, workshopGuests, total_guests, user }) => {
  const today = getTodayUTC()

  const { name, start, end, sign_in_code } = workshop.attributes

  const [visibleGuests, setVisibleGuests] = useState(false)

  // total_guests son los invitados con todos sus atributos de manera independiente
  // workshopGuests son los invitados solo con su id, el id del workshop y el sign_in_code
  const guests = filterGuests(workshopGuests, total_guests)

  const handleUpdate = () => handleWorkshop.update(workshop, guests)
  const handleDelete = () => handleWorkshop.delete(workshop)

  let color = "";
  let EyeIcon = () => (null);

  if (visibleGuests) {
    color = "success";
    EyeIcon = VisibleEye;

  } else {
    color = "dark";
    EyeIcon = HiddenEye;
  }

  return (
    //#cfd8dc
    <li className="workshopItem p-2 p-sm-3 text-dark">

      <div className="WorkshopItemBody p-2 p-sm-3 bg-light rounded">
        <span>
          <strong>{name}</strong>
        </span>
        <WorkshopItemInfo
          today={today}
          workshop={workshop.attributes}
        />
      </div>

      <div className="buttons">
        <ExpirationBadge
          today={today}
          end={end}
          code={sign_in_code}
        />

        <div className="buttons__container">
          
          <Button
            color={color}
            className="rounded-pill"
            handleClick={() => setVisibleGuests(!visibleGuests)}
          >
            <EyeIcon />
          </Button>

          <Button
            color="info"
            className="rounded-pill"
            handleClick={handleUpdate}
          >
            <Edit />
          </Button>

          <Button
            color="danger"
            className="rounded-pill"
            handleClick={handleDelete}
          >
            <Trash />
          </Button>

        </div>
      </div>

      {
        visibleGuests &&
        <WorkshopGuests
          guests={guests}
          workshop={workshop}
          workshopCreatorID={workshop.attributes.user_id}
        />
      }
    </li>
  )
}

const mapStateToProps = ({ Login: { total_guests, user: { attributes: { plan } } } }) => ({
  total_guests,
  user: {
    plan
  }
})

export default connect(mapStateToProps)(WorkshopListItem);