import React from 'react';
import { Alert, Button } from 'reactstrap';
import InputCheck from '../../widgets/inputCheck';
import InputDateModal from '../../widgets/inputDateModal';
import InputModal from '../../widgets/inputModal';

const GuestForm = (props) => {
  const { setRef, handleSubmit, handleInvalid, handleChange } = props
  const { title, guest, registerSuccess, registrationError, loading, errorMessage } = props

  return (
    <>
      <h1>{title}</h1>
      <form
        className="needs-validation"
        ref={setRef}
        onSubmit={handleSubmit}
        onInvalid={handleInvalid}
      >
        <div className="Register__body">

          {
            registerSuccess &&
            <Alert color="success">
              Se ha registrado con éxito
            </Alert>
          }
          {
            registrationError &&
            <Alert color="danger">
              {errorMessage}
            </Alert>
          }
          <InputModal
            id="name"
            type="text"
            title="Nombre y Apellido"
            autoComplete="name"
            className="form-control"
            placeholder="Ingresa tu nombre y tu apellido"
            handleChange={handleChange}
            defaultValue={guest.name}
          />
          <InputModal
            id="email"
            type="email"
            title="Email"
            autoComplete="email"
            className="form-control"
            placeholder="Ingresa tu email"
            handleChange={handleChange}
            defaultValue={guest.email}
          />
          <InputDateModal
            required={true}
            type="date"
            id="birth_date"
            title="Fecha de Nacimiento"
            autoComplete="bday"
            className="form-control"
            handleChange={handleChange}
            defaultValue={guest.birth_date}
          />
          <InputCheck
            id="Terminos-condiciones"
            title="Acepto lo términos y condiciones (click aquí para ver TyC)"
            containerClass="TyC"
            handleChange={handleChange}
            checked={guest.tc_accepted_at}
            setRefInputs={() => { }}
            link="/terminos-condiciones"
            required={true}
          />

          <div className="submit-btn-area">
            <Button disabled={loading} color="primary" className="btn btn-primary" type="submit">
              {loading ? 'Loading...' : 'Guardar'} <i className="ti-arrow-right"></i>
            </Button>
          </div>
        </div>
      </form>
    </>
  )
}
export default GuestForm;