import { SET_LOADING } from './actionTypes';
import { SET_TOTAL_USERS_AT_STORE, SET_ADMINS_AT_STORE, SET_TOTAL_GUEST_AT_STORE } from './actionTypes';
import { CHECK_LOGIN, SET_USER_SUCCESSFUL, SET_PLANS_AT_STORE, UPDATE_LOGGED_USER } from './actionTypes';
import { API_FAILED } from './actionTypes';
import { SET_GUESTS_AT_STORE, KNOWN_TYPE_OF_ERROR, NOTIFY_LOGIN_SUCCESSFUL, LOGOUT } from './actionTypes';

import { getState_from_LocalStorage } from '../../../helpers/utils'
import { initialLogin } from './initialState';

import { setState } from '../../utils';


let initialState = initialLogin;

const FIELD = 'Login';

if (localStorage.Login) {
  initialState = {
    ...getState_from_LocalStorage('Login'),
    loading: false
  }
} 

const loginReducer = (state = initialState, action) => {
  const commonAction = {
    [SET_LOADING]: "loading",
    [SET_PLANS_AT_STORE]: "plans",
    [SET_GUESTS_AT_STORE]: "userGuests",
    [SET_ADMINS_AT_STORE]: "total_admins",
    [SET_TOTAL_USERS_AT_STORE]: "total_users",
    [SET_TOTAL_GUEST_AT_STORE]: "total_guests",
  }

  const customAction = {
    [CHECK_LOGIN]: () => {
      return {
        ...state,
        user: null,
        loading: true,
        loginError: null
      }
    },
    [NOTIFY_LOGIN_SUCCESSFUL]: () => {
      return {
        ...state, login_success: true,
      }
    },
    [SET_USER_SUCCESSFUL]: () => {
      return {
        ...state,
        user: action.payload,
        loading: false,
        is_logged: true,
        loginError: null
      }
    },
    [UPDATE_LOGGED_USER]: () => {
      return {
        ...state,
        user: action.payload,
        loading: false,
        is_logged: true,
        loginError: null
      }
    },
    [LOGOUT]: () => {
      localStorage.clear()
      return {
        ...initialLogin,
        is_logged: false,
      }
    },
    [KNOWN_TYPE_OF_ERROR]: () => {
      return {
        ...state,
        known_error: true,
        messageErrorLogin: action.payload
      }
    },
    [API_FAILED]: () => {
      return {
        ...state,
        loading: false,
        loginError: true,
        login_success: null,
      }
    },
  }

  return setState(state, action, FIELD, commonAction, customAction);
}

export default loginReducer;